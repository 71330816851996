.quantity-alert-modal {
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 20%;
  position: absolute;
  border: 2px solid #f2f2f2;
  background-color: #fff;
}
.quantity-alert-modal-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
.quantity-alert-item-wrapper {
  width: 100%;
  list-style: none;
  padding: 0;
}
.quantity-alert-item-wrapper li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.quantity-alert-item-wrapper li > img {
  width: 6rem;
}
.quantity-alert-modal-title {
  font-size: 20px;
  font-weight: 800;
}

.quantity-alert-modal-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.quantity-alert-modal-action {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.quantity-alert-modal-container {
  display: flex;
  width: 100%;
  padding-left: 0.6rem;
}
